import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err)
// }
export default new Router({
  routes: [
    {
      path: "/main",
      name: "main",
      component: () => import("@/views/Home.vue"),
      meta: {
        keepAlive: true, // 需要缓存false//不需要缓存
        isBack: false,
      },
    },
    {
      path: "/search",
      name: "search",
      component: () => import("@/views/Search.vue"),
    },
    {
      path: "/detail/:id",
      name: "detail",
      component: () => import("@/views/Detail.vue"),
    },
    {
      path: "*", // 此处需特别注意置于最底部
      redirect: "/main",
    },
  ],
});
